.dot-menu{
	.menuzord{
		@media (min-width:992px){
			position: fixed;
			top:0;
			right:0;
			width:auto;
			height: auto;
			top:50%;
			transform: translatey(-50%);
			// background-color: $color__primary;

			.menuzord-brand{
				display: none;
			}
			.menuzord-menu{
				li{
					width:12px;
					height: 12px;
					display: block;
					float: none;
					margin-bottom: 20px;


					&.active a{
						background-color: $accent__light;
					}

					&:hover{
						span{
							right: 30px;
							opacity: 1;
						}
						a{
							background-color: $color__primary;
						}
					}


					a{
						position: relative;
						width: 12px;
						height: 12px;
						border-radius: 50%;						
						background-color: rgb(164, 167, 169);
						padding: 0;
						transition: all 0.4s;

						span{
							position: absolute;
							right: 25px;
							top:-3px;
							white-space: nowrap;
							background-color: $accent;
							border-radius: $primary-border-radius;
							opacity:0;
							transition: all 0.4s;
						}

					}
				}

			}

			.menuzord-brand{
				margin:60px 30px 0 35px;
			}

			&.navbar-solid,.solid{
				background-color: transparent;
				ul{
					padding-top:0;
					li a{
						padding: 0;
					}
				}
				.menuzord-brand{
					margin:60px 30px 0 35px;
					img{
						width: 100px;
					}
				}
			}
		}

	}
}